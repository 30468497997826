import { axios } from '@/utils/request'

/**
 * 查詢流程腳本
 *
 * @author yubaoshan
 * @date 2020/6/9 23:53
 */
export function flowableScriptPage (parameter) {
  return axios({
    url: '/flowableScript/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 添加流程腳本
 *
 * @author yubaoshan
 * @date 2020/6/9 23:53
 */
export function flowableScriptAdd (parameter) {
  return axios({
    url: '/flowableScript/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 刪除流程腳本
 *
 * @author yubaoshan
 * @date 2020/6/9 23:54
 */
export function flowableScriptDelete (parameter) {
  return axios({
    url: '/flowableScript/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 編輯流程分類
 *
 * @author yubaoshan
 * @date 2020/6/9 9:55
 */
export function flowableScriptEdit (parameter) {
  return axios({
    url: '/flowableScript/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 查看流程腳本
 *
 * @author yubaoshan
 * @date 2020/6/9 23:55
 */
export function flowableScriptDetail (parameter) {
  return axios({
    url: '/flowableScript/detail',
    method: 'post',
    data: parameter
  })
}

/**
 * 查詢流程腳本列表
 *
 * @author yubaoshan
 * @date 2020/8/13 17:50
 */
export function flowableScriptList (parameter) {
  return axios({
    url: '/flowableScript/list',
    method: 'get',
    params: parameter
  })
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { flowableScriptEdit } from '@/api/modular/flowable/scriptManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'

export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      typeData: [],
      langData: [],
      form: this.$form.createForm(this)
    }
  },
  methods: {
    // 初始化方法
    edit (record) {
      this.visible = true
      this.getTypeData()
      this.getLangData()
      setTimeout(() => {
        this.form.setFieldsValue(
          {
            id: record.id,
            name: record.name,
            type: record.type.toString(),
            lang: record.lang.toString(),
            content: record.content,
            remark: record.remark
          }
        )
      }, 100)
    },

    /**
     * 獲取類型下拉框數據
     */
    getTypeData () {
      sysDictTypeDropDown({ code: 'script_type' }).then((res) => {
        this.typeData = res.data
      })
    },

    /**
     * 獲取語言下拉框數據
     */
    getLangData () {
      sysDictTypeDropDown({ code: 'script_language_type' }).then((res) => {
        this.langData = res.data
      })
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          flowableScriptEdit(values).then((res) => {
            this.confirmLoading = false
            if (res.success) {
              this.$message.success('編輯成功')
              this.visible = false
              this.$emit('ok', values)
              this.form.resetFields()
            } else {
              this.$message.error('編輯失敗：' + res.message)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
    }
  }
}

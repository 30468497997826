//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { flowableScriptAdd } from '@/api/modular/flowable/scriptManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      typeData: [],
      langData: [],
      visible: false,
      confirmLoading: false,
      replaceFields: {
        key: 'id'
      },
      form: this.$form.createForm(this)
    }
  },
  methods: {
    // 初始化方法
    add () {
      this.visible = true
      this.getTypeData()
      this.getLangData()
    },

    /**
     * 獲取類型下拉框數據
     */
    getTypeData () {
      sysDictTypeDropDown({ code: 'script_type' }).then((res) => {
        this.typeData = res.data
      })
    },

    /**
     * 獲取語言下拉框數據
     */
    getLangData () {
      sysDictTypeDropDown({ code: 'script_language_type' }).then((res) => {
        this.langData = res.data
      })
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          flowableScriptAdd(values).then((res) => {
            if (res.success) {
              this.$message.success('新增成功')
              this.visible = false
              this.confirmLoading = false
              this.$emit('ok', values)
              this.form.resetFields()
            } else {
              this.$message.error('新增失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
    }
  }
}

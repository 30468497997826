//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, Ellipsis, XCard } from '@/components'
import { flowableScriptPage, flowableScriptDelete } from '@/api/modular/flowable/scriptManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import addForm from './addForm'
import editForm from './editForm'
import Detail from './detail'
export default {
  components: {
    XCard,
    Ellipsis,
    STable,
    addForm,
    editForm,
    Detail
  },
  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '名稱',
          dataIndex: 'name'
        },
        {
          title: '類別',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '語言',
          dataIndex: 'lang',
          scopedSlots: { customRender: 'lang' }
        },
        {
          title: '備註',
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return flowableScriptPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      typeData: [],
      langData: []
    }
  },
  created () {
    this.getDictData()
    if (this.hasPerm('flowableScript:edit') || this.hasPerm('flowableScript:delete')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    typeFilter (type) {
      // eslint-disable-next-line eqeqeq
      const values = this.typeData.filter(item => item.code == type)
      if (values.length > 0) {
        return values[0].value
      }
    },
    langFilter (lang) {
      // eslint-disable-next-line eqeqeq
      const values = this.langData.filter(item => item.code == lang)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 獲取字典翻譯數據
     */
    getDictData () {
      sysDictTypeDropDown({ code: 'script_type' }).then((res) => {
        this.typeData = res.data
      })
      sysDictTypeDropDown({ code: 'script_language_type' }).then((res) => {
        this.langData = res.data
      })
    },
    /**
     * 刪除
     * @param record
     */
    flowableScriptDelete (record) {
      flowableScriptDelete(record).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      })
    },
    handleClick (e) {
      this.queryParam = {
        pid: e.toString()
      }
      this.$refs.table.refresh(true)
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
